<template>
  <div>
    <!-- isian muali -->
    <div v-if="air && limbah && sanitasi && higiene && lingkungan">
      <div>
        <vue-html2pdf
          :show-layout="false"
          :enable-download="true"
          :preview-modal="true"
          :paginate-elements-by-height="1300"
          filename="formulir-pertanyaan-kunci"
          :pdf-quality="2"
          :manual-pagination="true"
          pdf-format="a4"
          pdf-orientation="portrait"
          pdf-content-width="100%"
          ref="html2Pdf"
          :html-to-pdf-options="htmlToPdfOptions"
        >
          <div slot="pdf-content" style="padding: 10px; height: 100%">
            <div class="html2pdf__page-break">
              <profile-detil-data :profile="profile" />
              <p class="small_txt color_txt">
                {{ $date(created).format("MMMM YYYY") }}
              </p>
              <div flat class="rounded-max mb-5 bg_header">
                <div class="fill_header">
                  <b>Air</b>
                </div>
              </div>
              <v-card class="bordered_card" outlined>
                <h5>1. Apa sumber air utama yang digunakan oleh Fasyankes ? 
                    (Sumber air yang paling banyak atau sering digunakan untuk kegiatan higiene dan sanitasi)</h5>
                <div style="margin: 0 20px">
                  <v-radio-group
                    dense
                    v-model="
                      air.Sumber_air_utama_yang_digunakan_oleh_fasyankes[0]
                        .dataPenyusun_desc
                    "
                    
                  >
                    <v-row>
                      <v-col cols="4">
                        <v-radio
                          label="Air perpipaan"
                          color="#00ccb6"
                          value="1"
                        ></v-radio>
                        <v-radio
                          label="Sumur bor/pompa"
                          color="#00ccb6"
                          value="2"
                        ></v-radio>
                        <v-radio
                          label="Sumur gali terlindungi"
                          color="#00ccb6"
                          value="3"
                        ></v-radio>
                        <v-radio
                          label="Penampungan air hujan"
                          color="#00ccb6"
                          value="4"
                        ></v-radio>
                      </v-col>
                      <v-col cols="4">
                        <v-radio
                          label="Mata air terlindungi"
                          color="#00ccb6"
                          value="5"
                        ></v-radio>
                        <v-radio
                          label="Penyediaan truk tangki"
                          color="#00ccb6"
                          value="6"
                        ></v-radio>
                        <v-radio
                          label="Mata air tidak terlindungi"
                          color="#00ccb6"
                          value="7"
                        ></v-radio>
                        <v-radio
                          label="Sumur gali tidak terlindungi"
                          color="#00ccb6"
                          value="8"
                        ></v-radio>
                      </v-col>
                      <v-col cols="4">
                        <v-radio
                          label="Air permukaan (sungai/danau/kanal)"
                          color="#00ccb6"
                          value="9"
                        ></v-radio>
                        <v-radio
                          label="Tidak ada sumber air"
                          color="#00ccb6"
                          value="10"
                        ></v-radio>
                        <v-radio
                          label="Sumber lainnya"
                          color="#00ccb6"
                          value="11"
                        ></v-radio>
                      </v-col>
                    </v-row>
                  </v-radio-group>
                </div>
              </v-card>
              <v-card class="bordered_card" outlined>
                <h5>2. Dimana lokasi sumber air utama yang digunakan oleh Fasyankes ?</h5>
                <div style="margin: 0 20px">
                  <v-radio-group
                    
                    v-model="
                      air.Sumber_air_utama_ada_di_fasilitas_pelayanan_kesehatan[0]
                        .dataPenyusun_desc
                    "
                    dense
                  >
                    <v-row>
                      <v-col>
                        <v-radio label="Di dalam area Fasyankes" color="#00ccb6" value="1"></v-radio>
                        <v-radio
                          label="Di luar area Fasyankes dalam jarak kurang dari 500 meter"
                          color="#00ccb6"
                          value="2"
                        ></v-radio>
                        <v-radio
                          label="Di luar area Fasyankes pada jarak lebih dari 500 meter"
                          color="#00ccb6"
                          value="3"
                        ></v-radio>
                      </v-col>
                    </v-row>
                  </v-radio-group>
                </div>
              </v-card>
              <v-card class="bordered_card" outlined>
                <h5>3. Apakah air dari sumber utama saat ini tersedia cukup dan memadai untuk seluruh aktivitas di Fasyankes ?</h5>
                <div style="margin: 0 20px">
                  <v-radio-group
                    
                    v-model="
                      air.Air_dari_sumber_utama_saat_ini_tersedia[0].dataPenyusun_desc
                    "
                    dense
                  >
                    <v-row>
                      <v-col>
                        <v-radio label="Ya" color="#00ccb6" value="1"></v-radio>
                        <v-radio label="Tidak" color="#00ccb6" value="2"></v-radio>
                      </v-col>
                    </v-row>
                  </v-radio-group>
                </div>
              </v-card>
              <v-card class="bordered_card" outlined>
                <h5>
                  4. Apakah air minum di Fasyankes memenuhi standar sisa klorin atau tidak ada E. coli ? 
                  (Air minum adalah air siap minum, standar sisa klorin air dari sumber perpipaan pada kondisi normal adalah 0,2 s.d. 0,5 mg/l atau E. Coli 0 per 100 ml)
                </h5>
                <div style="margin: 0 20px">
                  <v-radio-group
                    
                    v-model="
                      air[
                        'Air_minum_memiliki_sisa_klorin_yang_sesuai_0,2_mg_L_atau_0,5_mg_L_dalam_keadaan_darurat__atau_E._coli_100_ml'
                      ][0].dataPenyusun_desc
                    "
                    dense
                  >
                    <v-row>
                      <v-col>
                        <v-radio
                          label="Ya, air minum memenuhi standar"
                          color="#00ccb6"
                          value="1"
                        ></v-radio>
                        <v-radio
                          label="Tidak, air minum belum memenuhi standar"
                          color="#00ccb6"
                          value="2"
                        ></v-radio>
                        <v-radio
                          label="Air minum tidak diuji"
                          color="#00ccb6"
                          value="3"
                        ></v-radio>
                      </v-col>
                    </v-row>
                  </v-radio-group>
                </div>
              </v-card>
            </div>
            <div class="html2pdf__page-break">
              <div
                flat
                class="rounded-max mb-5 bg_header"
                style="margin-top: 10px"
              >
                <div class="fill_header">
                  <b>Sanitasi</b>
                </div>
              </div>
              <v-card class="bordered_card" outlined>
                <h5>5. Berapa jumlah toilet yang dapat digunakan (tersedia, berfungsi, privasi) di Fasyankes ? </h5>
                <div class="mt-5" style="margin: 0 20px">
                  <v-card
                    class="d-flex align-center pl-2 px-5"
                    outlined
                    style="width: max-content"
                  >
                    <p class="ma-0 small_txt color_txt">Jumlah</p>
                    <input
                      type="number"
                      
                      v-model="
                        sanitasi
                          .Jumlah_toilet_yang_dapat_digunakan__tersedia_fungsional_pribadi__untuk_fasilitas_pelayanan_kesehatan[0]
                          .dataPenyusun_desc
                      "
                      class="pa-2 custom_input"
                    />
                  </v-card>
                </div>
              </v-card>
              <v-card class="bordered_card" outlined>
                <h5>6. Apa jenis toilet/jamban yang paling umum digunakan di Fasyankes ?</h5>
                <div style="margin: 0 20px">
                  <v-radio-group
                    
                    v-model="
                      sanitasi.Jenis_toilet_jamban__pilih_salah_satu_paling_umum_[0]
                        .dataPenyusun_desc
                    "
                    dense
                  >
                    <v-row>
                      <v-col cols="8">
                        <v-radio
                          label="Kloset siram yang tersambung dengan Instalasi Pengolah Air Limbah (IPAL)"
                          color="#00ccb6"
                          value="1"
                        ></v-radio>
                        <v-radio
                          label="Kloset siram yang tersambung dengan tangki septik"
                          color="#00ccb6"
                          value="2"
                        ></v-radio>
                        <v-radio
                          label="Kloset siram yang tersambung ke saluran terbuka"
                          color="#00ccb6"
                          value="3"
                        ></v-radio>
                        <v-radio
                          label="Plengsengan dengan tutup"
                          color="#00ccb6"
                          value="4"
                        ></v-radio>
                      </v-col>
                      <v-col cols="4">
                        <v-radio
                          label="Plengsengan tanpa tutup"
                          color="#00ccb6"
                          value="5"
                        ></v-radio>
                        <v-radio
                          label="Ember"
                          color="#00ccb6"
                          value="Ember"
                        ></v-radio>
                        <v-radio
                          label="Toilet gantung"
                          color="#00ccb6"
                          value="7"
                        ></v-radio>
                        <v-radio
                          label="Ada toilet tapi tidak berfungsi/tidak ada toilet/jamban"
                          color="#00ccb6"
                          value="6"
                        ></v-radio>
                      </v-col>
                    </v-row>
                  </v-radio-group>
                </div>
              </v-card>
              <v-card class="bordered_card" outlined>
                <h5>7. Apakah toilet dipisah untuk petugas dan pasien ?</h5>
                <div style="margin: 0 20px">
                  <v-radio-group
                    
                    v-model="
                      sanitasi.Toilet_terpisah_untuk_staf_dan_pasien[0]
                        .dataPenyusun_desc
                    "
                    dense
                  >
                    <v-row>
                      <v-col>
                        <v-radio label="Ya" color="#00ccb6" value="1"></v-radio>
                        <v-radio label="Tidak" color="#00ccb6" value="2"></v-radio>
                      </v-col>
                    </v-row>
                  </v-radio-group>
                </div>
              </v-card>
              <v-card class="bordered_card" outlined>
                <h5>
                  8. Apakah tersedia toilet yang terpisah dengan jelas untuk pria dan wanita atau terdapat privasi jika gender netral ? 
                  (Privasi pada toilet gender netral misalnya berupa kamar-kamar yang tertutup dengan sekat permanen atau kamar tunggal)
                </h5>
                <div style="margin: 0 20px">
                  <v-radio-group
                    
                    v-model="
                      sanitasi.Toilet_dipisahkan_dengan_jelas_untuk_pria_dan_wanita_atau_memberikan_privasi__misal__kamar_kamar_tunggal__jika_netral_gender[0]
                      .dataPenyusun_desc
                    "
                    dense
                  >
                    <v-row>
                      <v-col>
                        <v-radio label="Ya" color="#00ccb6" value="1"></v-radio>
                        <v-radio
                          label="Tidak"
                          color="#00ccb6"
                          value="2"
                        ></v-radio>
                      </v-col>
                    </v-row>
                  </v-radio-group>
                </div>
              </v-card>
              <v-card class="bordered_card" outlined>
                <h5>
                  9. Apakah toilet wanita memiliki fasilitas untuk manajemen kebersihan menstruasi (MKM) ? 
                  (MKM dapat berupa tempat sampah tertutup serta air dan sabun)
                </h5>
                <div style="margin: 0 20px">
                  <v-radio-group
                    
                    v-model="
                      sanitasi[
                        'Toilet_wanita_memiliki_fasilitas_untuk_manajemen_kebutuhan_menstruasi__tempat_sampah_tertutup,_dan_atau_air_dan_sabun_'
                      ][0].dataPenyusun_desc
                    "
                    dense
                  >
                    <v-row>
                      <v-col>
                        <v-radio label="Ya" color="#00ccb6" value="1"></v-radio>
                        <v-radio label="Tidak" color="#00ccb6" value="2"></v-radio>
                      </v-col>
                    </v-row>
                  </v-radio-group>
                </div>
              </v-card>
              <v-card class="bordered_card" outlined>
                <h5>
                  10. Apakah tersedia setidaknya satu toilet yang dapat diakses oleh orang dengan mobilitas terbatas ? 
                  (Toilet dilengkapi dengan pegangan tangan, pintunya cukup lebar dan terbuka keluar, lantainya rata dan dapat dilalui kursi roda, dll)
                </h5>
                <div style="margin: 0 20px">
                  <v-radio-group
                    
                    v-model="
                      sanitasi
                        .Setidaknya_satu_toilet_dapat_diakses_oleh_orang_orang_dengan_mobilitas_terbatas[0]
                        .dataPenyusun_desc
                    "
                    dense
                  >
                    <v-row>
                      <v-col>
                        <v-radio label="Ya" color="#00ccb6" value="1"></v-radio>
                        <v-radio label="Tidak" color="#00ccb6" value="2"></v-radio>
                      </v-col>
                    </v-row>
                  </v-radio-group>
                </div>
              </v-card>
            </div>
            <div class="html2pdf__page-break">
              <v-card class="bordered_card" outlined>
                <h5>
                  11. Apakah Fasyankes memiliki jumlah toilet yang cukup untuk digunakan oleh petugas, pasien, dan pengunjung ? 
                  (Jumlah toilet yang cukup dapat dipastikan salah satunya dari tidak adanya antrean)
                </h5>
                <div style="margin: 0 20px">
                  <v-radio-group
                    
                    v-model="
                      sanitasi
                        .Fasilitas_memiliki_jumlah_toilet_yang_cukup_dan_dapat_digunakan_untuk_pasien[0]
                        .dataPenyusun_desc
                    "
                    dense
                  >
                    <v-row>
                      <v-col>
                        <v-radio label="Ya" color="#00ccb6" value="1"></v-radio>
                        <v-radio
                          label="Tidak"
                          color="#00ccb6"
                          value="2"
                        ></v-radio>
                      </v-col>
                    </v-row>
                  </v-radio-group>
                </div>
              </v-card>
            </div>
            <div class="html2pdf__page-break">
              <div flat class="rounded-max mb-5 bg_header">
                <div class="fill_header">
                  <b>Higiene</b>
                </div>
              </div>
              <v-card class="bordered_card" outlined>
                <h5>
                  12. Apakah sabun dan air atau antiseptik berbasis alkohol tersedia di ruang konsultasi/perawatan ?
                </h5>
                <div style="margin: 0 20px">
                  <v-radio-group
                    
                    v-model="
                      higiene
                        .Sabun_dan_air_atau_antiseptik_berbasis_alkohol_tersedia_diruang_konsultasi[0]
                        .dataPenyusun_desc
                    "
                    dense
                  >
                    <v-row>
                      <v-col>
                        <v-radio label="Ya" color="#00ccb6" value="1"></v-radio>
                        <v-radio
                          label="Ya, tersedia di sebagian ruangan"
                          color="#00ccb6"
                          value="2"
                        ></v-radio>
                        <v-radio label="Tidak" color="#00ccb6" value="3"></v-radio>
                      </v-col>
                    </v-row>
                  </v-radio-group>
                </div>
              </v-card>
              <v-card class="bordered_card" outlined>
                <h5>13. Apakah sabun dan air tersedia di toilet ?</h5>
                <div style="margin: 0 20px">
                  <v-radio-group
                    
                    v-model="
                      higiene.Sabun_dan_air_tersedia_di_toilet[0].dataPenyusun_desc
                    "
                    dense
                  >
                    <v-row>
                      <v-col>
                        <v-radio
                          label="Ya dengan jarak kurang dari 5 meter"
                          color="#00ccb6"
                          value="1"
                        ></v-radio>
                        <v-radio
                          label="Ya dengan jarak lebih dari 5 meter"
                          color="#00ccb6"
                          value="2"
                        ></v-radio>
                        <v-radio label="Tidak" color="#00ccb6" value="3"></v-radio>
                      </v-col>
                    </v-row>
                  </v-radio-group>
                </div>
              </v-card>
              <v-card class="bordered_card" outlined>
                <h5>
                  14. Apakah pemantauan kepatuhan kebersihan tangan dilakukan dengan pengamatan langsung pada lima momen mencuci tangan ? 
                  (Lima momen cuci tangan terdiri dari: sebelum kontak dengan pasien dan tindakan aseptik serta setelah terkena cairan tubuh pasien, kontak dengan pasien, dan kontak dengan lingkungan di sekitar pasien)
                </h5>
                <div style="margin: 0 20px">
                  <v-radio-group
                    
                    v-model="
                      higiene
                        .Pemantauan_Kepatuhan_kebersihan_tangan_dilakukan_dengan_pengamatan_langsung_dilihat_dari_5_momen_mencuci_tangan[0]
                        .dataPenyusun_desc
                    "
                    dense
                  >
                    <v-row>
                      <v-col>
                        <v-radio label="Ya" color="#00ccb6" value="1"></v-radio>
                        <v-radio
                          label="Tidak"
                          color="#00ccb6"
                          value="2"
                        ></v-radio>
                      </v-col>
                    </v-row>
                  </v-radio-group>
                </div>
              </v-card>
            </div>
            <div class="html2pdf__page-break">
              <div flat class="rounded-max mb-5 bg_header">
                <div class="fill_header">
                  <b>Limbah</b>
                </div>
              </div>
              <v-card class="bordered_card" outlined>
                <h5>
                  15. Apakah limbah benda tajam, infeksius, dan domestik dipisahkan dengan aman di dalam tiga wadah berbeda di ruang konsultasi/perawatan ?
                </h5>
                <div style="margin: 0 20px">
                  <v-radio-group 
                  
                  v-model="
                    limbah
                      .Limbah_benda_tajam_infeksius_dan_domestik_dipisahkan_dengan_aman_menjadi_3_wadah_di_ruang_konsultasi[0]
                      .dataPenyusun_desc
                  "
                  dense>
                    <v-row>
                      <v-col>
                        <v-radio label="Ya" color="#00ccb6" value="1"></v-radio>
                        <v-radio
                          label="Ya, di sebagian ruangan"
                          color="#00ccb6"
                          value="2"
                        ></v-radio>
                        <v-radio label="Tidak" color="#00ccb6" value="3"></v-radio>
                      </v-col>
                    </v-row>
                  </v-radio-group>
                </div>
              </v-card>
              <v-card class="bordered_card" outlined>
                <h5>16. Bagaimana cara pengolahan/pembuangan limbah benda tajam ?</h5>
                <div style="margin: 0 20px">
                  <v-radio-group
                    
                    v-model="
                      limbah.pengolahan_pembuangan_limbah_benda_tajam[0]
                        .dataPenyusun_desc
                    "
                    dense
                  >
                    <v-row>
                      <v-col cols="4">
                        <v-radio
                          label="Diolah dengan autoklaf atau microwave berizin"
                          color="#00ccb6"
                          value="1"
                        ></v-radio>
                        <v-radio
                          label="Diolah dengan insinerator berizin"
                          color="#00ccb6"
                          value="2"
                        ></v-radio>
                        <v-radio
                          label="Diolah dengan insinerator tidak berizin"
                          color="#00ccb6"
                          value="3"
                        ></v-radio>
                        <v-radio
                          label="Dilakukan pembakaran di lubang terlindungi"
                          color="#00ccb6"
                          value="4"
                        ></v-radio>
                      </v-col>
                      <v-col cols="4">
                        <v-radio
                          label="Dikubur di dalam lubang yang dilindungi dan dilapisi"
                          color="#00ccb6"
                          value="5"
                        ></v-radio>
                        <v-radio
                          label="Dikumpulkan untuk pengolahan limbah medis bekerja sama dengan pengolah berizin"
                          color="#00ccb6"
                          value="6"
                        ></v-radio>
                        <v-radio
                          label="Dilakukan pembuangan terbuka tanpa diolah"
                          color="#00ccb6"
                          value="7"
                        ></v-radio>
                      </v-col>
                      <v-col cols="4">
                        <v-radio
                          label="Dilakukan pembakaran terbuka"
                          color="#00ccb6"
                          value="8"
                        ></v-radio>
                        <v-radio
                          label="Tidak diolah dan dibuang bersama limbah domestik"
                          color="#00ccb6"
                          value="9"
                        ></v-radio>
                        <v-radio
                          label="Lainnya"
                          color="#00ccb6"
                          value="10"
                        ></v-radio>
                      </v-col>
                    </v-row>
                  </v-radio-group>
                </div>
              </v-card>
              <div class="html2pdf__page-break">
                <v-card class="bordered_card" outlined>
                  <h5>17. Bagaimana cara pengolahan/pembuangan limbah infeksius ?</h5>
                  <div style="margin: 0 20px">
                    <v-radio-group
                      
                      v-model="
                        limbah.pengolahan_pembuangan_limbah_infeksius[0]
                          .dataPenyusun_desc
                      "
                      dense
                    >
                      <v-row>
                        <v-col cols="4">
                          <v-radio
                            label="Diolah dengan autoklaf atau microwave berizin atau disinfeksi"
                            color="#00ccb6"
                            value="1"
                          ></v-radio>
                          <v-radio
                            label="Diolah dengan insinerator berizin"
                            color="#00ccb6"
                            value="2"
                          ></v-radio>
                          <v-radio
                            label="Diolah dengan insinerator tidak berizin"
                            color="#00ccb6"
                            value="3"
                          ></v-radio>
                          <v-radio
                            label="Dilakukan pembakaran di lubang terlindungi"
                            color="#00ccb6"
                            value="4"
                          ></v-radio>
                        </v-col>
                        <v-col cols="4">
                          <v-radio
                            label="Dikubur di dalam lubang yang dilindungi dan dilapisi"
                            color="#00ccb6"
                            value="5"
                          ></v-radio>
                          <v-radio
                            label="Dikumpulkan untuk pengolahan limbah medis bekerja sama dengan pengolah berizin"
                            color="#00ccb6"
                            value="6"
                          ></v-radio>
                          <v-radio
                            label="Dilakukan pembuangan terbuka tanpa diolah"
                            color="#00ccb6"
                            value="7"
                          ></v-radio>
                        </v-col>
                        <v-col cols="4">
                          <v-radio
                            label="Dilakukan pembakaran terbuka"
                            color="#00ccb6"
                            value="8"
                          ></v-radio>
                          <v-radio
                            label="Tidak diolah dan dibuang bersama limbah domestik"
                            color="#00ccb6"
                            value="9"
                          ></v-radio>
                          <v-radio
                            label="Lainnya"
                            color="#00ccb6"
                            value="10"
                          ></v-radio>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </div>
                </v-card>
                <v-card class="bordered_card" outlined>
                  <h5>
                    18. Apakah pengurangan limbah medis dan domestik yang terdiri dari prevention (pencegahan timbulan limbah), 
                    reduce (pengurangan limbah), reuse (guna ulang), 
                    dan recycle (daur ulang) telah dilakukan ?
                  </h5>
                  <div style="margin: 0 20px">
                    <v-radio-group
                      
                      v-model="
                        limbah[
                          'pengurangan_limbah_medis,_domestik_terdiri_dari_prevention_pencegahan__timbulan_limbah_reduce_pengurangan_limbah_reuse_gunakan_kembali_recyle_daur_ulang_telah_dilakukan'
                        ][0].dataPenyusun_desc
                      "
                      dense
                    >
                      <v-row>
                        <v-col>
                          <v-radio
                            label="Ya dan sudah menjadi ketetapan"
                            color="#00ccb6"
                            value="1"
                          ></v-radio>
                          <v-radio
                            label="Ya tetapi pelaksanaannya tidak konsisten"
                            color="#00ccb6"
                            value="2"
                          ></v-radio>
                          <v-radio
                            label="Tidak pernah dilakukan"
                            color="#00ccb6"
                            value="3"
                          ></v-radio>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </div>
                </v-card>
              </div>
            </div>
            <div class="html2pdf__page-break">
              <div flat class="rounded-max mb-5 bg_header">
                <div class="fill_header">
                  <b>Kebersihan Lingkungan</b>
                </div>
              </div>
              <v-card class="bordered_card" outlined>
                <h5>
                  19. Apakah prosedur untuk pembersihan ruangan, toilet, dan tumpahan darah atau cairan tubuh dan jadwal pembersihannya tersedia ?
                </h5>
                <div style="margin: 0 20px">
                  <v-radio-group
                    
                    v-model="
                      lingkungan
                        .Protokol_untuk_pembersihan_lantai_wastafel_tumpahan_darah_atau_cairan_tubuh_dan_jadwal_pemberseihan_tersedia[0]
                        .dataPenyusun_desc
                    "
                    dense
                  >
                    <v-row>
                      <v-col>
                        <v-radio label="Ya" color="#00ccb6" value="1"></v-radio>
                        <v-radio label="Tidak" color="#00ccb6" value="2"></v-radio>
                      </v-col>
                    </v-row>
                  </v-radio-group>
                </div>
              </v-card>
              <v-card class="bordered_card" outlined>
                <h5>
                  20. Apakah semua petugas kebersihan telah menerima pelatihan/materi terkait kebersihan ?
                </h5>
                <div style="margin: 0 20px">
                  <v-radio-group
                    
                    v-model="
                      lingkungan
                        .Semua_staf_bertanggung_jawab_untuk_pembersihan_telah_menerima_pelatihan[0]
                        .dataPenyusun_desc
                    "
                    dense
                  >
                    <v-row>
                      <v-col>
                        <v-radio label="Ya" color="#00ccb6" value="1"></v-radio>
                        <v-radio
                          label="Ya, sebagian"
                          color="#00ccb6"
                          value="2"
                        ></v-radio>
                        <v-radio label="Tidak" color="#00ccb6" value="3"></v-radio>
                      </v-col>
                    </v-row>
                  </v-radio-group>
                </div>
              </v-card>
              <v-card class="bordered_card" outlined>
                <h5>
                  21. Apakah catatan pemantauan kebersihan tersedia untuk area perawatan/bangsal umum/fasilitas lainnya dan ditandatangani oleh petugas kebersihan yang relevan setiap hari di setiap area ?
                </h5>
                <div style="margin: 0 20px">
                  <v-radio-group
                    
                    v-model="
                      lingkungan
                        .Catatan_pemantauan_kebersihan_tersedia_untuk_area_perawatan_pasien___bangsal_umum___fasilitas_dan_ditandatangani_oleh_petugas_kebersihan_yang_relevan_setiap_hari_di_setiap_area___bangsal___seluruh_fasilitas[0]
                        .dataPenyusun_desc
                    "
                    dense
                  >
                    <v-row>
                      <v-col>
                        <v-radio label="Ya" color="#00ccb6" value="1"></v-radio>
                        <v-radio
                          label="Ya, sebagian"
                          color="#00ccb6"
                          value="2"
                        ></v-radio>
                        <v-radio
                          label="Tidak"
                          color="#00ccb6"
                          value="3"
                        ></v-radio>
                      </v-col>
                    </v-row>
                  </v-radio-group>
                </div>
              </v-card>
            </div>
          </div>
        </vue-html2pdf>
      </div>
    </div>
    <!-- end -->
  </div>
</template>

<script>
import VueHtml2pdf from "vue-html2pdf";
import profileDetilData from "../../Data/Detail/profileDetilData.vue";

export default {
  props: ["id", "profile", "created"],
  components: { VueHtml2pdf, profileDetilData },
  computed: {
    air() {
      let data = null;
      if (this.isianData) {
        data = this.isianData.Air.Air;
      }
      return data;
    },
    sanitasi() {
      let data = null;
      if (this.isianData) {
        data = this.isianData.Toilet.Sanitasi;
      }
      return data;
    },
    higiene() {
      let data = null;
      if (this.isianData) {
        data = this.isianData.Manajemen_dan_Tenaga_Kerja.Higiene;
      }
      return data;
    },
    limbah() {
      let data = null;
      if (this.isianData) {
        data = this.isianData.Pengelolaan_Limbah_Medis.Limbah;
      }
      return data;
    },
    lingkungan() {
      let data = null;
      if (this.isianData) {
        data = this.isianData.Kebersihan_Lingkungan.Kebersihan_Lingkungan;
      }
      return data;
    },
  },
  name: "printKunciKesling",
  data() {
    return {
      loading: false,
      isianData: null,
      htmlToPdfOptions: {
        filename: 'formulir-pertanyaan-kunci',
        // pdf-quality: 2,
        html2canvas: {
          scrollX: 0,
          scrollY: 0,
        },
      },
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.loading = true;
      let body = {
        report_id: this.id ? this.id : this.$route.params.id,
      };
      this.$store.dispatch("formulir/getPertanyaanKunci", body).then((data) => {
        this.isianData = data.data.Kesehatan_Lingkungan;
      });
    },
    generateForm() {
      this.$refs.html2Pdf.generatePdf();
    },
  },
};
</script>

<style scoped>
.custom_input {
  border-radius: 8px;
  width: 150px;
}
.custom_input::placeholder {
  font-size: small;
}
.custom_input:focus {
  outline: none;
}
.custom_mini {
  border-radius: 8px;
  width: 100px;
}
.custom_mini::placeholder {
  font-size: small;
}
.custom_mini:focus {
  outline: none;
}
.custom_long_input {
  border-radius: 8px;
  width: 100%;
}
.custom_long_input:focus {
  outline: none;
}
.custom_long_input::placeholder {
  font-size: small;
}

.custom_date {
  width: 120px;
}

.custom_date::placeholder {
  font-size: small;
}
.custom_date:focus {
  outline: none;
}
.temp_stepper_header {
  background: none !important;
  box-shadow: none !important;
}

.number_unactive {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #ececec;
  color: #5d5d5d;
  font-size: small;
  margin-right: 3px;
}
.number_active {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #fff;
  color: #00b4cc;
  font-size: small;
  margin-right: 3px;
}
.marlef {
  margin-right: 50px;
}
.side_toolbar {
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  display: flex;
  align-items: center;
  z-index: 99 !important;
}
.item_toolbar {
  margin: 0 20px;
  padding: 15px;
  background: #fff;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: rgb(0 180 204 / 18%) 0px 5px 7px !important;
}
.fill_header {
  background: #00b4cc !important;
  width: 45%;
  padding: 10px;
  color: #fff;
}

.bordered_card {
  padding: 10px;
  margin-bottom: 10px;
}
.pa-2 {
  padding: 5px;
}
.px-5 {
  padding: 0 15px;
}
.py-3 {
  padding: 10px 0;
}
.mb-3 {
  margin-bottom: 12px;
}
.mb-4 {
  margin-bottom: 15px;
}
.mb-5 {
  margin-bottom: 20px;
}
.d-flex {
  display: flex;
}
.ma-0 {
  margin: 0;
}
.align-center {
  align-items: center;
}
.justify-center {
  justify-content: center;
}
.bg_header{
  background: #CFEEF2;
  border-radius: 5px;
}
</style>