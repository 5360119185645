var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.air && _vm.limbah && _vm.sanitasi && _vm.higiene && _vm.lingkungan)?_c('div',[_c('div',[_c('vue-html2pdf',{ref:"html2Pdf",attrs:{"show-layout":false,"enable-download":true,"preview-modal":true,"paginate-elements-by-height":1300,"filename":"formulir-pertanyaan-kunci","pdf-quality":2,"manual-pagination":true,"pdf-format":"a4","pdf-orientation":"portrait","pdf-content-width":"100%","html-to-pdf-options":_vm.htmlToPdfOptions}},[_c('div',{staticStyle:{"padding":"10px","height":"100%"},attrs:{"slot":"pdf-content"},slot:"pdf-content"},[_c('div',{staticClass:"html2pdf__page-break"},[_c('profile-detil-data',{attrs:{"profile":_vm.profile}}),_c('p',{staticClass:"small_txt color_txt"},[_vm._v(" "+_vm._s(_vm.$date(_vm.created).format("MMMM YYYY"))+" ")]),_c('div',{staticClass:"rounded-max mb-5 bg_header",attrs:{"flat":""}},[_c('div',{staticClass:"fill_header"},[_c('b',[_vm._v("Air")])])]),_c('v-card',{staticClass:"bordered_card",attrs:{"outlined":""}},[_c('h5',[_vm._v("1. Apa sumber air utama yang digunakan oleh Fasyankes ? (Sumber air yang paling banyak atau sering digunakan untuk kegiatan higiene dan sanitasi)")]),_c('div',{staticStyle:{"margin":"0 20px"}},[_c('v-radio-group',{attrs:{"dense":""},model:{value:(
                    _vm.air.Sumber_air_utama_yang_digunakan_oleh_fasyankes[0]
                      .dataPenyusun_desc
                  ),callback:function ($$v) {_vm.$set(_vm.air.Sumber_air_utama_yang_digunakan_oleh_fasyankes[0]
                      , "dataPenyusun_desc", $$v)},expression:"\n                    air.Sumber_air_utama_yang_digunakan_oleh_fasyankes[0]\n                      .dataPenyusun_desc\n                  "}},[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-radio',{attrs:{"label":"Air perpipaan","color":"#00ccb6","value":"1"}}),_c('v-radio',{attrs:{"label":"Sumur bor/pompa","color":"#00ccb6","value":"2"}}),_c('v-radio',{attrs:{"label":"Sumur gali terlindungi","color":"#00ccb6","value":"3"}}),_c('v-radio',{attrs:{"label":"Penampungan air hujan","color":"#00ccb6","value":"4"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-radio',{attrs:{"label":"Mata air terlindungi","color":"#00ccb6","value":"5"}}),_c('v-radio',{attrs:{"label":"Penyediaan truk tangki","color":"#00ccb6","value":"6"}}),_c('v-radio',{attrs:{"label":"Mata air tidak terlindungi","color":"#00ccb6","value":"7"}}),_c('v-radio',{attrs:{"label":"Sumur gali tidak terlindungi","color":"#00ccb6","value":"8"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-radio',{attrs:{"label":"Air permukaan (sungai/danau/kanal)","color":"#00ccb6","value":"9"}}),_c('v-radio',{attrs:{"label":"Tidak ada sumber air","color":"#00ccb6","value":"10"}}),_c('v-radio',{attrs:{"label":"Sumber lainnya","color":"#00ccb6","value":"11"}})],1)],1)],1)],1)]),_c('v-card',{staticClass:"bordered_card",attrs:{"outlined":""}},[_c('h5',[_vm._v("2. Dimana lokasi sumber air utama yang digunakan oleh Fasyankes ?")]),_c('div',{staticStyle:{"margin":"0 20px"}},[_c('v-radio-group',{attrs:{"dense":""},model:{value:(
                    _vm.air.Sumber_air_utama_ada_di_fasilitas_pelayanan_kesehatan[0]
                      .dataPenyusun_desc
                  ),callback:function ($$v) {_vm.$set(_vm.air.Sumber_air_utama_ada_di_fasilitas_pelayanan_kesehatan[0]
                      , "dataPenyusun_desc", $$v)},expression:"\n                    air.Sumber_air_utama_ada_di_fasilitas_pelayanan_kesehatan[0]\n                      .dataPenyusun_desc\n                  "}},[_c('v-row',[_c('v-col',[_c('v-radio',{attrs:{"label":"Di dalam area Fasyankes","color":"#00ccb6","value":"1"}}),_c('v-radio',{attrs:{"label":"Di luar area Fasyankes dalam jarak kurang dari 500 meter","color":"#00ccb6","value":"2"}}),_c('v-radio',{attrs:{"label":"Di luar area Fasyankes pada jarak lebih dari 500 meter","color":"#00ccb6","value":"3"}})],1)],1)],1)],1)]),_c('v-card',{staticClass:"bordered_card",attrs:{"outlined":""}},[_c('h5',[_vm._v("3. Apakah air dari sumber utama saat ini tersedia cukup dan memadai untuk seluruh aktivitas di Fasyankes ?")]),_c('div',{staticStyle:{"margin":"0 20px"}},[_c('v-radio-group',{attrs:{"dense":""},model:{value:(
                    _vm.air.Air_dari_sumber_utama_saat_ini_tersedia[0].dataPenyusun_desc
                  ),callback:function ($$v) {_vm.$set(_vm.air.Air_dari_sumber_utama_saat_ini_tersedia[0], "dataPenyusun_desc", $$v)},expression:"\n                    air.Air_dari_sumber_utama_saat_ini_tersedia[0].dataPenyusun_desc\n                  "}},[_c('v-row',[_c('v-col',[_c('v-radio',{attrs:{"label":"Ya","color":"#00ccb6","value":"1"}}),_c('v-radio',{attrs:{"label":"Tidak","color":"#00ccb6","value":"2"}})],1)],1)],1)],1)]),_c('v-card',{staticClass:"bordered_card",attrs:{"outlined":""}},[_c('h5',[_vm._v(" 4. Apakah air minum di Fasyankes memenuhi standar sisa klorin atau tidak ada E. coli ? (Air minum adalah air siap minum, standar sisa klorin air dari sumber perpipaan pada kondisi normal adalah 0,2 s.d. 0,5 mg/l atau E. Coli 0 per 100 ml) ")]),_c('div',{staticStyle:{"margin":"0 20px"}},[_c('v-radio-group',{attrs:{"dense":""},model:{value:(
                    _vm.air[
                      'Air_minum_memiliki_sisa_klorin_yang_sesuai_0,2_mg_L_atau_0,5_mg_L_dalam_keadaan_darurat__atau_E._coli_100_ml'
                    ][0].dataPenyusun_desc
                  ),callback:function ($$v) {_vm.$set(_vm.air[
                      'Air_minum_memiliki_sisa_klorin_yang_sesuai_0,2_mg_L_atau_0,5_mg_L_dalam_keadaan_darurat__atau_E._coli_100_ml'
                    ][0], "dataPenyusun_desc", $$v)},expression:"\n                    air[\n                      'Air_minum_memiliki_sisa_klorin_yang_sesuai_0,2_mg_L_atau_0,5_mg_L_dalam_keadaan_darurat__atau_E._coli_100_ml'\n                    ][0].dataPenyusun_desc\n                  "}},[_c('v-row',[_c('v-col',[_c('v-radio',{attrs:{"label":"Ya, air minum memenuhi standar","color":"#00ccb6","value":"1"}}),_c('v-radio',{attrs:{"label":"Tidak, air minum belum memenuhi standar","color":"#00ccb6","value":"2"}}),_c('v-radio',{attrs:{"label":"Air minum tidak diuji","color":"#00ccb6","value":"3"}})],1)],1)],1)],1)])],1),_c('div',{staticClass:"html2pdf__page-break"},[_c('div',{staticClass:"rounded-max mb-5 bg_header",staticStyle:{"margin-top":"10px"},attrs:{"flat":""}},[_c('div',{staticClass:"fill_header"},[_c('b',[_vm._v("Sanitasi")])])]),_c('v-card',{staticClass:"bordered_card",attrs:{"outlined":""}},[_c('h5',[_vm._v("5. Berapa jumlah toilet yang dapat digunakan (tersedia, berfungsi, privasi) di Fasyankes ? ")]),_c('div',{staticClass:"mt-5",staticStyle:{"margin":"0 20px"}},[_c('v-card',{staticClass:"d-flex align-center pl-2 px-5",staticStyle:{"width":"max-content"},attrs:{"outlined":""}},[_c('p',{staticClass:"ma-0 small_txt color_txt"},[_vm._v("Jumlah")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                      _vm.sanitasi
                        .Jumlah_toilet_yang_dapat_digunakan__tersedia_fungsional_pribadi__untuk_fasilitas_pelayanan_kesehatan[0]
                        .dataPenyusun_desc
                    ),expression:"\n                      sanitasi\n                        .Jumlah_toilet_yang_dapat_digunakan__tersedia_fungsional_pribadi__untuk_fasilitas_pelayanan_kesehatan[0]\n                        .dataPenyusun_desc\n                    "}],staticClass:"pa-2 custom_input",attrs:{"type":"number"},domProps:{"value":(
                      _vm.sanitasi
                        .Jumlah_toilet_yang_dapat_digunakan__tersedia_fungsional_pribadi__untuk_fasilitas_pelayanan_kesehatan[0]
                        .dataPenyusun_desc
                    )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.sanitasi
                        .Jumlah_toilet_yang_dapat_digunakan__tersedia_fungsional_pribadi__untuk_fasilitas_pelayanan_kesehatan[0]
                        , "dataPenyusun_desc", $event.target.value)}}})])],1)]),_c('v-card',{staticClass:"bordered_card",attrs:{"outlined":""}},[_c('h5',[_vm._v("6. Apa jenis toilet/jamban yang paling umum digunakan di Fasyankes ?")]),_c('div',{staticStyle:{"margin":"0 20px"}},[_c('v-radio-group',{attrs:{"dense":""},model:{value:(
                    _vm.sanitasi.Jenis_toilet_jamban__pilih_salah_satu_paling_umum_[0]
                      .dataPenyusun_desc
                  ),callback:function ($$v) {_vm.$set(_vm.sanitasi.Jenis_toilet_jamban__pilih_salah_satu_paling_umum_[0]
                      , "dataPenyusun_desc", $$v)},expression:"\n                    sanitasi.Jenis_toilet_jamban__pilih_salah_satu_paling_umum_[0]\n                      .dataPenyusun_desc\n                  "}},[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('v-radio',{attrs:{"label":"Kloset siram yang tersambung dengan Instalasi Pengolah Air Limbah (IPAL)","color":"#00ccb6","value":"1"}}),_c('v-radio',{attrs:{"label":"Kloset siram yang tersambung dengan tangki septik","color":"#00ccb6","value":"2"}}),_c('v-radio',{attrs:{"label":"Kloset siram yang tersambung ke saluran terbuka","color":"#00ccb6","value":"3"}}),_c('v-radio',{attrs:{"label":"Plengsengan dengan tutup","color":"#00ccb6","value":"4"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-radio',{attrs:{"label":"Plengsengan tanpa tutup","color":"#00ccb6","value":"5"}}),_c('v-radio',{attrs:{"label":"Ember","color":"#00ccb6","value":"Ember"}}),_c('v-radio',{attrs:{"label":"Toilet gantung","color":"#00ccb6","value":"7"}}),_c('v-radio',{attrs:{"label":"Ada toilet tapi tidak berfungsi/tidak ada toilet/jamban","color":"#00ccb6","value":"6"}})],1)],1)],1)],1)]),_c('v-card',{staticClass:"bordered_card",attrs:{"outlined":""}},[_c('h5',[_vm._v("7. Apakah toilet dipisah untuk petugas dan pasien ?")]),_c('div',{staticStyle:{"margin":"0 20px"}},[_c('v-radio-group',{attrs:{"dense":""},model:{value:(
                    _vm.sanitasi.Toilet_terpisah_untuk_staf_dan_pasien[0]
                      .dataPenyusun_desc
                  ),callback:function ($$v) {_vm.$set(_vm.sanitasi.Toilet_terpisah_untuk_staf_dan_pasien[0]
                      , "dataPenyusun_desc", $$v)},expression:"\n                    sanitasi.Toilet_terpisah_untuk_staf_dan_pasien[0]\n                      .dataPenyusun_desc\n                  "}},[_c('v-row',[_c('v-col',[_c('v-radio',{attrs:{"label":"Ya","color":"#00ccb6","value":"1"}}),_c('v-radio',{attrs:{"label":"Tidak","color":"#00ccb6","value":"2"}})],1)],1)],1)],1)]),_c('v-card',{staticClass:"bordered_card",attrs:{"outlined":""}},[_c('h5',[_vm._v(" 8. Apakah tersedia toilet yang terpisah dengan jelas untuk pria dan wanita atau terdapat privasi jika gender netral ? (Privasi pada toilet gender netral misalnya berupa kamar-kamar yang tertutup dengan sekat permanen atau kamar tunggal) ")]),_c('div',{staticStyle:{"margin":"0 20px"}},[_c('v-radio-group',{attrs:{"dense":""},model:{value:(
                    _vm.sanitasi.Toilet_dipisahkan_dengan_jelas_untuk_pria_dan_wanita_atau_memberikan_privasi__misal__kamar_kamar_tunggal__jika_netral_gender[0]
                    .dataPenyusun_desc
                  ),callback:function ($$v) {_vm.$set(_vm.sanitasi.Toilet_dipisahkan_dengan_jelas_untuk_pria_dan_wanita_atau_memberikan_privasi__misal__kamar_kamar_tunggal__jika_netral_gender[0]
                    , "dataPenyusun_desc", $$v)},expression:"\n                    sanitasi.Toilet_dipisahkan_dengan_jelas_untuk_pria_dan_wanita_atau_memberikan_privasi__misal__kamar_kamar_tunggal__jika_netral_gender[0]\n                    .dataPenyusun_desc\n                  "}},[_c('v-row',[_c('v-col',[_c('v-radio',{attrs:{"label":"Ya","color":"#00ccb6","value":"1"}}),_c('v-radio',{attrs:{"label":"Tidak","color":"#00ccb6","value":"2"}})],1)],1)],1)],1)]),_c('v-card',{staticClass:"bordered_card",attrs:{"outlined":""}},[_c('h5',[_vm._v(" 9. Apakah toilet wanita memiliki fasilitas untuk manajemen kebersihan menstruasi (MKM) ? (MKM dapat berupa tempat sampah tertutup serta air dan sabun) ")]),_c('div',{staticStyle:{"margin":"0 20px"}},[_c('v-radio-group',{attrs:{"dense":""},model:{value:(
                    _vm.sanitasi[
                      'Toilet_wanita_memiliki_fasilitas_untuk_manajemen_kebutuhan_menstruasi__tempat_sampah_tertutup,_dan_atau_air_dan_sabun_'
                    ][0].dataPenyusun_desc
                  ),callback:function ($$v) {_vm.$set(_vm.sanitasi[
                      'Toilet_wanita_memiliki_fasilitas_untuk_manajemen_kebutuhan_menstruasi__tempat_sampah_tertutup,_dan_atau_air_dan_sabun_'
                    ][0], "dataPenyusun_desc", $$v)},expression:"\n                    sanitasi[\n                      'Toilet_wanita_memiliki_fasilitas_untuk_manajemen_kebutuhan_menstruasi__tempat_sampah_tertutup,_dan_atau_air_dan_sabun_'\n                    ][0].dataPenyusun_desc\n                  "}},[_c('v-row',[_c('v-col',[_c('v-radio',{attrs:{"label":"Ya","color":"#00ccb6","value":"1"}}),_c('v-radio',{attrs:{"label":"Tidak","color":"#00ccb6","value":"2"}})],1)],1)],1)],1)]),_c('v-card',{staticClass:"bordered_card",attrs:{"outlined":""}},[_c('h5',[_vm._v(" 10. Apakah tersedia setidaknya satu toilet yang dapat diakses oleh orang dengan mobilitas terbatas ? (Toilet dilengkapi dengan pegangan tangan, pintunya cukup lebar dan terbuka keluar, lantainya rata dan dapat dilalui kursi roda, dll) ")]),_c('div',{staticStyle:{"margin":"0 20px"}},[_c('v-radio-group',{attrs:{"dense":""},model:{value:(
                    _vm.sanitasi
                      .Setidaknya_satu_toilet_dapat_diakses_oleh_orang_orang_dengan_mobilitas_terbatas[0]
                      .dataPenyusun_desc
                  ),callback:function ($$v) {_vm.$set(_vm.sanitasi
                      .Setidaknya_satu_toilet_dapat_diakses_oleh_orang_orang_dengan_mobilitas_terbatas[0]
                      , "dataPenyusun_desc", $$v)},expression:"\n                    sanitasi\n                      .Setidaknya_satu_toilet_dapat_diakses_oleh_orang_orang_dengan_mobilitas_terbatas[0]\n                      .dataPenyusun_desc\n                  "}},[_c('v-row',[_c('v-col',[_c('v-radio',{attrs:{"label":"Ya","color":"#00ccb6","value":"1"}}),_c('v-radio',{attrs:{"label":"Tidak","color":"#00ccb6","value":"2"}})],1)],1)],1)],1)])],1),_c('div',{staticClass:"html2pdf__page-break"},[_c('v-card',{staticClass:"bordered_card",attrs:{"outlined":""}},[_c('h5',[_vm._v(" 11. Apakah Fasyankes memiliki jumlah toilet yang cukup untuk digunakan oleh petugas, pasien, dan pengunjung ? (Jumlah toilet yang cukup dapat dipastikan salah satunya dari tidak adanya antrean) ")]),_c('div',{staticStyle:{"margin":"0 20px"}},[_c('v-radio-group',{attrs:{"dense":""},model:{value:(
                    _vm.sanitasi
                      .Fasilitas_memiliki_jumlah_toilet_yang_cukup_dan_dapat_digunakan_untuk_pasien[0]
                      .dataPenyusun_desc
                  ),callback:function ($$v) {_vm.$set(_vm.sanitasi
                      .Fasilitas_memiliki_jumlah_toilet_yang_cukup_dan_dapat_digunakan_untuk_pasien[0]
                      , "dataPenyusun_desc", $$v)},expression:"\n                    sanitasi\n                      .Fasilitas_memiliki_jumlah_toilet_yang_cukup_dan_dapat_digunakan_untuk_pasien[0]\n                      .dataPenyusun_desc\n                  "}},[_c('v-row',[_c('v-col',[_c('v-radio',{attrs:{"label":"Ya","color":"#00ccb6","value":"1"}}),_c('v-radio',{attrs:{"label":"Tidak","color":"#00ccb6","value":"2"}})],1)],1)],1)],1)])],1),_c('div',{staticClass:"html2pdf__page-break"},[_c('div',{staticClass:"rounded-max mb-5 bg_header",attrs:{"flat":""}},[_c('div',{staticClass:"fill_header"},[_c('b',[_vm._v("Higiene")])])]),_c('v-card',{staticClass:"bordered_card",attrs:{"outlined":""}},[_c('h5',[_vm._v(" 12. Apakah sabun dan air atau antiseptik berbasis alkohol tersedia di ruang konsultasi/perawatan ? ")]),_c('div',{staticStyle:{"margin":"0 20px"}},[_c('v-radio-group',{attrs:{"dense":""},model:{value:(
                    _vm.higiene
                      .Sabun_dan_air_atau_antiseptik_berbasis_alkohol_tersedia_diruang_konsultasi[0]
                      .dataPenyusun_desc
                  ),callback:function ($$v) {_vm.$set(_vm.higiene
                      .Sabun_dan_air_atau_antiseptik_berbasis_alkohol_tersedia_diruang_konsultasi[0]
                      , "dataPenyusun_desc", $$v)},expression:"\n                    higiene\n                      .Sabun_dan_air_atau_antiseptik_berbasis_alkohol_tersedia_diruang_konsultasi[0]\n                      .dataPenyusun_desc\n                  "}},[_c('v-row',[_c('v-col',[_c('v-radio',{attrs:{"label":"Ya","color":"#00ccb6","value":"1"}}),_c('v-radio',{attrs:{"label":"Ya, tersedia di sebagian ruangan","color":"#00ccb6","value":"2"}}),_c('v-radio',{attrs:{"label":"Tidak","color":"#00ccb6","value":"3"}})],1)],1)],1)],1)]),_c('v-card',{staticClass:"bordered_card",attrs:{"outlined":""}},[_c('h5',[_vm._v("13. Apakah sabun dan air tersedia di toilet ?")]),_c('div',{staticStyle:{"margin":"0 20px"}},[_c('v-radio-group',{attrs:{"dense":""},model:{value:(
                    _vm.higiene.Sabun_dan_air_tersedia_di_toilet[0].dataPenyusun_desc
                  ),callback:function ($$v) {_vm.$set(_vm.higiene.Sabun_dan_air_tersedia_di_toilet[0], "dataPenyusun_desc", $$v)},expression:"\n                    higiene.Sabun_dan_air_tersedia_di_toilet[0].dataPenyusun_desc\n                  "}},[_c('v-row',[_c('v-col',[_c('v-radio',{attrs:{"label":"Ya dengan jarak kurang dari 5 meter","color":"#00ccb6","value":"1"}}),_c('v-radio',{attrs:{"label":"Ya dengan jarak lebih dari 5 meter","color":"#00ccb6","value":"2"}}),_c('v-radio',{attrs:{"label":"Tidak","color":"#00ccb6","value":"3"}})],1)],1)],1)],1)]),_c('v-card',{staticClass:"bordered_card",attrs:{"outlined":""}},[_c('h5',[_vm._v(" 14. Apakah pemantauan kepatuhan kebersihan tangan dilakukan dengan pengamatan langsung pada lima momen mencuci tangan ? (Lima momen cuci tangan terdiri dari: sebelum kontak dengan pasien dan tindakan aseptik serta setelah terkena cairan tubuh pasien, kontak dengan pasien, dan kontak dengan lingkungan di sekitar pasien) ")]),_c('div',{staticStyle:{"margin":"0 20px"}},[_c('v-radio-group',{attrs:{"dense":""},model:{value:(
                    _vm.higiene
                      .Pemantauan_Kepatuhan_kebersihan_tangan_dilakukan_dengan_pengamatan_langsung_dilihat_dari_5_momen_mencuci_tangan[0]
                      .dataPenyusun_desc
                  ),callback:function ($$v) {_vm.$set(_vm.higiene
                      .Pemantauan_Kepatuhan_kebersihan_tangan_dilakukan_dengan_pengamatan_langsung_dilihat_dari_5_momen_mencuci_tangan[0]
                      , "dataPenyusun_desc", $$v)},expression:"\n                    higiene\n                      .Pemantauan_Kepatuhan_kebersihan_tangan_dilakukan_dengan_pengamatan_langsung_dilihat_dari_5_momen_mencuci_tangan[0]\n                      .dataPenyusun_desc\n                  "}},[_c('v-row',[_c('v-col',[_c('v-radio',{attrs:{"label":"Ya","color":"#00ccb6","value":"1"}}),_c('v-radio',{attrs:{"label":"Tidak","color":"#00ccb6","value":"2"}})],1)],1)],1)],1)])],1),_c('div',{staticClass:"html2pdf__page-break"},[_c('div',{staticClass:"rounded-max mb-5 bg_header",attrs:{"flat":""}},[_c('div',{staticClass:"fill_header"},[_c('b',[_vm._v("Limbah")])])]),_c('v-card',{staticClass:"bordered_card",attrs:{"outlined":""}},[_c('h5',[_vm._v(" 15. Apakah limbah benda tajam, infeksius, dan domestik dipisahkan dengan aman di dalam tiga wadah berbeda di ruang konsultasi/perawatan ? ")]),_c('div',{staticStyle:{"margin":"0 20px"}},[_c('v-radio-group',{attrs:{"dense":""},model:{value:(
                  _vm.limbah
                    .Limbah_benda_tajam_infeksius_dan_domestik_dipisahkan_dengan_aman_menjadi_3_wadah_di_ruang_konsultasi[0]
                    .dataPenyusun_desc
                ),callback:function ($$v) {_vm.$set(_vm.limbah
                    .Limbah_benda_tajam_infeksius_dan_domestik_dipisahkan_dengan_aman_menjadi_3_wadah_di_ruang_konsultasi[0]
                    , "dataPenyusun_desc", $$v)},expression:"\n                  limbah\n                    .Limbah_benda_tajam_infeksius_dan_domestik_dipisahkan_dengan_aman_menjadi_3_wadah_di_ruang_konsultasi[0]\n                    .dataPenyusun_desc\n                "}},[_c('v-row',[_c('v-col',[_c('v-radio',{attrs:{"label":"Ya","color":"#00ccb6","value":"1"}}),_c('v-radio',{attrs:{"label":"Ya, di sebagian ruangan","color":"#00ccb6","value":"2"}}),_c('v-radio',{attrs:{"label":"Tidak","color":"#00ccb6","value":"3"}})],1)],1)],1)],1)]),_c('v-card',{staticClass:"bordered_card",attrs:{"outlined":""}},[_c('h5',[_vm._v("16. Bagaimana cara pengolahan/pembuangan limbah benda tajam ?")]),_c('div',{staticStyle:{"margin":"0 20px"}},[_c('v-radio-group',{attrs:{"dense":""},model:{value:(
                    _vm.limbah.pengolahan_pembuangan_limbah_benda_tajam[0]
                      .dataPenyusun_desc
                  ),callback:function ($$v) {_vm.$set(_vm.limbah.pengolahan_pembuangan_limbah_benda_tajam[0]
                      , "dataPenyusun_desc", $$v)},expression:"\n                    limbah.pengolahan_pembuangan_limbah_benda_tajam[0]\n                      .dataPenyusun_desc\n                  "}},[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-radio',{attrs:{"label":"Diolah dengan autoklaf atau microwave berizin","color":"#00ccb6","value":"1"}}),_c('v-radio',{attrs:{"label":"Diolah dengan insinerator berizin","color":"#00ccb6","value":"2"}}),_c('v-radio',{attrs:{"label":"Diolah dengan insinerator tidak berizin","color":"#00ccb6","value":"3"}}),_c('v-radio',{attrs:{"label":"Dilakukan pembakaran di lubang terlindungi","color":"#00ccb6","value":"4"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-radio',{attrs:{"label":"Dikubur di dalam lubang yang dilindungi dan dilapisi","color":"#00ccb6","value":"5"}}),_c('v-radio',{attrs:{"label":"Dikumpulkan untuk pengolahan limbah medis bekerja sama dengan pengolah berizin","color":"#00ccb6","value":"6"}}),_c('v-radio',{attrs:{"label":"Dilakukan pembuangan terbuka tanpa diolah","color":"#00ccb6","value":"7"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-radio',{attrs:{"label":"Dilakukan pembakaran terbuka","color":"#00ccb6","value":"8"}}),_c('v-radio',{attrs:{"label":"Tidak diolah dan dibuang bersama limbah domestik","color":"#00ccb6","value":"9"}}),_c('v-radio',{attrs:{"label":"Lainnya","color":"#00ccb6","value":"10"}})],1)],1)],1)],1)]),_c('div',{staticClass:"html2pdf__page-break"},[_c('v-card',{staticClass:"bordered_card",attrs:{"outlined":""}},[_c('h5',[_vm._v("17. Bagaimana cara pengolahan/pembuangan limbah infeksius ?")]),_c('div',{staticStyle:{"margin":"0 20px"}},[_c('v-radio-group',{attrs:{"dense":""},model:{value:(
                      _vm.limbah.pengolahan_pembuangan_limbah_infeksius[0]
                        .dataPenyusun_desc
                    ),callback:function ($$v) {_vm.$set(_vm.limbah.pengolahan_pembuangan_limbah_infeksius[0]
                        , "dataPenyusun_desc", $$v)},expression:"\n                      limbah.pengolahan_pembuangan_limbah_infeksius[0]\n                        .dataPenyusun_desc\n                    "}},[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-radio',{attrs:{"label":"Diolah dengan autoklaf atau microwave berizin atau disinfeksi","color":"#00ccb6","value":"1"}}),_c('v-radio',{attrs:{"label":"Diolah dengan insinerator berizin","color":"#00ccb6","value":"2"}}),_c('v-radio',{attrs:{"label":"Diolah dengan insinerator tidak berizin","color":"#00ccb6","value":"3"}}),_c('v-radio',{attrs:{"label":"Dilakukan pembakaran di lubang terlindungi","color":"#00ccb6","value":"4"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-radio',{attrs:{"label":"Dikubur di dalam lubang yang dilindungi dan dilapisi","color":"#00ccb6","value":"5"}}),_c('v-radio',{attrs:{"label":"Dikumpulkan untuk pengolahan limbah medis bekerja sama dengan pengolah berizin","color":"#00ccb6","value":"6"}}),_c('v-radio',{attrs:{"label":"Dilakukan pembuangan terbuka tanpa diolah","color":"#00ccb6","value":"7"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-radio',{attrs:{"label":"Dilakukan pembakaran terbuka","color":"#00ccb6","value":"8"}}),_c('v-radio',{attrs:{"label":"Tidak diolah dan dibuang bersama limbah domestik","color":"#00ccb6","value":"9"}}),_c('v-radio',{attrs:{"label":"Lainnya","color":"#00ccb6","value":"10"}})],1)],1)],1)],1)]),_c('v-card',{staticClass:"bordered_card",attrs:{"outlined":""}},[_c('h5',[_vm._v(" 18. Apakah pengurangan limbah medis dan domestik yang terdiri dari prevention (pencegahan timbulan limbah), reduce (pengurangan limbah), reuse (guna ulang), dan recycle (daur ulang) telah dilakukan ? ")]),_c('div',{staticStyle:{"margin":"0 20px"}},[_c('v-radio-group',{attrs:{"dense":""},model:{value:(
                      _vm.limbah[
                        'pengurangan_limbah_medis,_domestik_terdiri_dari_prevention_pencegahan__timbulan_limbah_reduce_pengurangan_limbah_reuse_gunakan_kembali_recyle_daur_ulang_telah_dilakukan'
                      ][0].dataPenyusun_desc
                    ),callback:function ($$v) {_vm.$set(_vm.limbah[
                        'pengurangan_limbah_medis,_domestik_terdiri_dari_prevention_pencegahan__timbulan_limbah_reduce_pengurangan_limbah_reuse_gunakan_kembali_recyle_daur_ulang_telah_dilakukan'
                      ][0], "dataPenyusun_desc", $$v)},expression:"\n                      limbah[\n                        'pengurangan_limbah_medis,_domestik_terdiri_dari_prevention_pencegahan__timbulan_limbah_reduce_pengurangan_limbah_reuse_gunakan_kembali_recyle_daur_ulang_telah_dilakukan'\n                      ][0].dataPenyusun_desc\n                    "}},[_c('v-row',[_c('v-col',[_c('v-radio',{attrs:{"label":"Ya dan sudah menjadi ketetapan","color":"#00ccb6","value":"1"}}),_c('v-radio',{attrs:{"label":"Ya tetapi pelaksanaannya tidak konsisten","color":"#00ccb6","value":"2"}}),_c('v-radio',{attrs:{"label":"Tidak pernah dilakukan","color":"#00ccb6","value":"3"}})],1)],1)],1)],1)])],1)],1),_c('div',{staticClass:"html2pdf__page-break"},[_c('div',{staticClass:"rounded-max mb-5 bg_header",attrs:{"flat":""}},[_c('div',{staticClass:"fill_header"},[_c('b',[_vm._v("Kebersihan Lingkungan")])])]),_c('v-card',{staticClass:"bordered_card",attrs:{"outlined":""}},[_c('h5',[_vm._v(" 19. Apakah prosedur untuk pembersihan ruangan, toilet, dan tumpahan darah atau cairan tubuh dan jadwal pembersihannya tersedia ? ")]),_c('div',{staticStyle:{"margin":"0 20px"}},[_c('v-radio-group',{attrs:{"dense":""},model:{value:(
                    _vm.lingkungan
                      .Protokol_untuk_pembersihan_lantai_wastafel_tumpahan_darah_atau_cairan_tubuh_dan_jadwal_pemberseihan_tersedia[0]
                      .dataPenyusun_desc
                  ),callback:function ($$v) {_vm.$set(_vm.lingkungan
                      .Protokol_untuk_pembersihan_lantai_wastafel_tumpahan_darah_atau_cairan_tubuh_dan_jadwal_pemberseihan_tersedia[0]
                      , "dataPenyusun_desc", $$v)},expression:"\n                    lingkungan\n                      .Protokol_untuk_pembersihan_lantai_wastafel_tumpahan_darah_atau_cairan_tubuh_dan_jadwal_pemberseihan_tersedia[0]\n                      .dataPenyusun_desc\n                  "}},[_c('v-row',[_c('v-col',[_c('v-radio',{attrs:{"label":"Ya","color":"#00ccb6","value":"1"}}),_c('v-radio',{attrs:{"label":"Tidak","color":"#00ccb6","value":"2"}})],1)],1)],1)],1)]),_c('v-card',{staticClass:"bordered_card",attrs:{"outlined":""}},[_c('h5',[_vm._v(" 20. Apakah semua petugas kebersihan telah menerima pelatihan/materi terkait kebersihan ? ")]),_c('div',{staticStyle:{"margin":"0 20px"}},[_c('v-radio-group',{attrs:{"dense":""},model:{value:(
                    _vm.lingkungan
                      .Semua_staf_bertanggung_jawab_untuk_pembersihan_telah_menerima_pelatihan[0]
                      .dataPenyusun_desc
                  ),callback:function ($$v) {_vm.$set(_vm.lingkungan
                      .Semua_staf_bertanggung_jawab_untuk_pembersihan_telah_menerima_pelatihan[0]
                      , "dataPenyusun_desc", $$v)},expression:"\n                    lingkungan\n                      .Semua_staf_bertanggung_jawab_untuk_pembersihan_telah_menerima_pelatihan[0]\n                      .dataPenyusun_desc\n                  "}},[_c('v-row',[_c('v-col',[_c('v-radio',{attrs:{"label":"Ya","color":"#00ccb6","value":"1"}}),_c('v-radio',{attrs:{"label":"Ya, sebagian","color":"#00ccb6","value":"2"}}),_c('v-radio',{attrs:{"label":"Tidak","color":"#00ccb6","value":"3"}})],1)],1)],1)],1)]),_c('v-card',{staticClass:"bordered_card",attrs:{"outlined":""}},[_c('h5',[_vm._v(" 21. Apakah catatan pemantauan kebersihan tersedia untuk area perawatan/bangsal umum/fasilitas lainnya dan ditandatangani oleh petugas kebersihan yang relevan setiap hari di setiap area ? ")]),_c('div',{staticStyle:{"margin":"0 20px"}},[_c('v-radio-group',{attrs:{"dense":""},model:{value:(
                    _vm.lingkungan
                      .Catatan_pemantauan_kebersihan_tersedia_untuk_area_perawatan_pasien___bangsal_umum___fasilitas_dan_ditandatangani_oleh_petugas_kebersihan_yang_relevan_setiap_hari_di_setiap_area___bangsal___seluruh_fasilitas[0]
                      .dataPenyusun_desc
                  ),callback:function ($$v) {_vm.$set(_vm.lingkungan
                      .Catatan_pemantauan_kebersihan_tersedia_untuk_area_perawatan_pasien___bangsal_umum___fasilitas_dan_ditandatangani_oleh_petugas_kebersihan_yang_relevan_setiap_hari_di_setiap_area___bangsal___seluruh_fasilitas[0]
                      , "dataPenyusun_desc", $$v)},expression:"\n                    lingkungan\n                      .Catatan_pemantauan_kebersihan_tersedia_untuk_area_perawatan_pasien___bangsal_umum___fasilitas_dan_ditandatangani_oleh_petugas_kebersihan_yang_relevan_setiap_hari_di_setiap_area___bangsal___seluruh_fasilitas[0]\n                      .dataPenyusun_desc\n                  "}},[_c('v-row',[_c('v-col',[_c('v-radio',{attrs:{"label":"Ya","color":"#00ccb6","value":"1"}}),_c('v-radio',{attrs:{"label":"Ya, sebagian","color":"#00ccb6","value":"2"}}),_c('v-radio',{attrs:{"label":"Tidak","color":"#00ccb6","value":"3"}})],1)],1)],1)],1)])],1)])])],1)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }